import { PdftronViewerDialogComponent } from '../../../../../files/shared/pdftron-viewer-dialog/pdftron-viewer-dialog/pdftron-viewer-dialog.component';
import { MobileFilesHelper } from '../../helpers/mobile-files.helper';
import { SdSnackBarDisplaySnackBar } from '../../../../../shared/sd-snack-bar/store/sd-snack-bar.actions';
import { dictionarySelector } from '../../../../../dictionary/store/dictionary.selectors';
import { AfterViewChecked, Component } from '@angular/core';
import {
  APP_BASE_HREF,
  AsyncPipe,
  NgClass,
  NgIf,
  PlatformLocation,
} from '@angular/common';
import { DictionaryPipeModule } from '../../../../../dictionary/pipes/dictionary-pipe/dictionary-pipe.module';
import { SdFlatButtonA11yModule } from '../../../../../shared/sd-flat-button-a11y/sd-flat-button-a11y.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { SdSvgA11yModule } from '../../../../../shared/sd-svg-a11y/sd-svg-a11y.module';
import { SdProgressSpinnerA11yModule } from 'src/app/shared/sd-progress-spinner-a11y/sd-progress-spinner-a11y/sd-progress-spinner-a11y.module';
import { FileUpload } from 'src/app/files/models/file-upload.model';
import {
  activeRecordRoleSelector,
  activeRecordSelector,
} from 'src/app/records/store/records-list.selectors';
import {
  activeSideDrawerLicensePrioritySelector,
  activeSideDrawerSelector,
} from 'src/app/sidedrawer/store/sidedrawer.selector';
import { tap } from 'rxjs';
import { FileItem } from 'src/app/files/models/file-item.model';
import { SfrWorkflowStatus } from 'src/app/reminders/models/enums/sfr-workflow-status.enum';
import { FileHistoryDisplayType } from 'src/app/files/models/file-history-display-type.model';
import { getRecordRole, RecordsRoles } from 'src/app/core/roles/records.roles';

@Component({
  selector: 'app-mobile-pdftron-viewer-dialog',
  template: `
    <div class="pdftron-viewer-dialog">
      <mat-progress-bar
        [ngClass]="
          uploadSpinner()
            ? 'primary-progress-bar'
            : 'primary-progress-bar invisible'
        "
        [value]="progress()"
        mode="determinate" />

      <div *ngIf="!data?.hideHeader" class="pdftron-viewer-dialog-header">
        <div class="pdftron-viewer-dialog-header-title">
          {{ data.fileItem.uploadTitle }}
        </div>

        <app-sd-svg-a11y
          (click)="onClose()"
          class="pdftron-viewer-dialog-header-button"
          (keydown.enter)="onClose()"
          [color]="sdColorPalette.primaryColor"
          [height]="1.5"
          [setSdAccessibility]="{
            role: 'button',
            ariaLabel: 'globalparams_close' | dictionary | async,
            tabIndex: 0,
          }"
          [src]="cdn + ('globalparams_closeicon' | dictionary | async)"
          [width]="1.5"></app-sd-svg-a11y>
      </div>

      <div class="pdftron-viewer-dialog-content">
        <div *ngIf="spinner()" class="pdftron-viewer-dialog-spinner">
          <app-sd-progress-spinner-a11y></app-sd-progress-spinner-a11y>
        </div>

        <div
          *ngIf="loadError && spinner() === false"
          [innerHTML]="'pdftronviewerdialog_body' | dictionary | async"
          class="pdftron-viewer-dialog-spinner pdftron-viewer-dialog-error"></div>

        <div #viewer class="web-viewer"></div>
      </div>

      <div
        *ngIf="spinner() === false && !loadError"
        class="pdftron-viewer-dialog-footer">
        <app-sd-flat-button-a11y
          (buttonClicked)="onDownloadOriginalMobile()"
          *ngIf="displayType().displaytype_showbuttondownloadoriginal"
          [loading]="downloadOriginalSpinner()"
          [primary]="false"
          >{{ displayType().displaytype_labelbuttondownloadoriginal }}
        </app-sd-flat-button-a11y>

        <app-sd-flat-button-a11y
          (buttonClicked)="onDownloadAsPDFMobile()"
          *ngIf="displayType().displaytype_showbuttondownloadpdf"
          [loading]="downloadPDFSpinner()"
          [primary]="false"
          >{{ displayType().displaytype_labelbuttondownloadpdf }}
        </app-sd-flat-button-a11y>

        <app-sd-flat-button-a11y
          (buttonClicked)="onPrint()"
          *ngIf="displayType().displaytype_showbuttonprint"
          [primary]="false"
          >{{ displayType().displaytype_labelbuttonprint }}
        </app-sd-flat-button-a11y>

        <app-sd-flat-button-a11y
          (buttonClicked)="onUploadPDFClickHandler()"
          *ngIf="displayType().displaytype_showbuttonsave"
          [disabled]="disableSignButton() | async"
          [loading]="uploadSpinner()"
          [primary]="true"
          >{{ displayType().displaytype_labelbuttonsave }}
        </app-sd-flat-button-a11y>
      </div>

      <div
        *ngIf="spinner() === false && loadError"
        class="pdftron-viewer-dialog-footer">
        <app-sd-flat-button-a11y (buttonClicked)="onClose()" [primary]="false"
          >{{ 'pdftronviewerdialog_close' | dictionary | async }}
        </app-sd-flat-button-a11y>
      </div>
    </div>
  `,
  styleUrls: [
    '../../../../../files/shared/pdftron-viewer-dialog/pdftron-viewer-dialog/pdftron-viewer-dialog.component.scss',
  ],
  imports: [
    AsyncPipe,
    DictionaryPipeModule,
    NgIf,
    SdFlatButtonA11yModule,
    MatProgressBarModule,
    MatIconModule,
    NgClass,
    SdSvgA11yModule,
    SdProgressSpinnerA11yModule,
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
      deps: [PlatformLocation],
    },
  ],
})
export class MobilePdftronViewerDialogComponent
  extends PdftronViewerDialogComponent
  implements AfterViewChecked
{
  async onDownloadAsPDFMobile(): Promise<void> {
    return this.saveFileLocallyMobile(await this.getBlobFromPDF());
  }

  onDownloadOriginalMobile(): Promise<void> {
    return this.saveFileLocallyMobile(this.originalBlob);
  }

  private async saveFileLocallyMobile(blob: Blob): Promise<void> {
    this.downloadOriginalSpinner.set(true);
    this.changeDetector.detectChanges();
    const success = await MobileFilesHelper.saveFileInDevice(
      blob,
      this.data.fileItem
    )
      .then(() => {
        return true;
      })
      .catch(e => {
        console.error(e);
        return false;
      })
      .finally(() => {
        this.downloadOriginalSpinner.set(false);
        this.changeDetector.detectChanges();
      });
    this.store.dispatch(
      new SdSnackBarDisplaySnackBar({
        message: !success
          ? (this.store.selectSignal(dictionarySelector)()
              ?.globalparams_downloadfails ?? 'globalparams_downloadfails')
          : (this.store.selectSignal(dictionarySelector)()
              ?.globalparams_downloadsuccess ?? 'globalparams_downloadsuccess'),
        duration: 3000,
        verticalPosition: 'bottom',
      })
    );
  }

  uploadPDF(fileUpload: FileUpload): void {
    const activeRecord = this.store.selectSignal(activeRecordSelector)();
    const activeSD = this.store.selectSignal(activeSideDrawerSelector)();
    const licensePriority = this.store.selectSignal(
      activeSideDrawerLicensePrioritySelector
    )();
    const roles = this.store.selectSignal(activeRecordRoleSelector)();
    this.progress.set(0);
    this.filesService
      .uploadFile(fileUpload, fileUpload.fileType, activeSD.id, activeRecord.id)
      .pipe(
        tap(() => {
          this.uploadSpinner.set(true);
          this.changeDetector.detectChanges();
        })
      )
      .subscribe({
        next: (response: {
          message: number;
          status: string;
          fileItem?: FileItem;
        }) => {
          this.progress.set(response.message);
          if (
            response.status === 'complete' &&
            activeRecord.status === SfrWorkflowStatus.sfrNew
          ) {
            this.updateSfrRecordStatus(activeSD.id, activeRecord);
            this.uploadSpinner.set(false);
            this.changeDetector.detectChanges();
          }
          if (
            response.status === 'complete' &&
            fileUpload.displayType === FileHistoryDisplayType.sign
          ) {
            this.signFileHistory(
              activeSD.id,
              activeRecord.id,
              response.fileItem
            );
            this.uploadSpinner.set(false);
            this.changeDetector.detectChanges();
          }
          if (
            response.status === 'complete' &&
            fileUpload.displayType !== FileHistoryDisplayType.sign
          ) {
            this.updateRecordFileHistory(activeSD.id, activeRecord.id);
            this.uploadSpinner.set(false);
            this.changeDetector.detectChanges();

            this.showSnackBar(
              this.displayType()?.displaytypealert_successuploadbody ?? ''
            );
          }
        },
        error: error => {
          this.uploadSpinner.set(false);
          this.changeDetector.detectChanges();
          console.error({ error });
          this.updateRecordFileHistory(activeSD.id, activeRecord.id);
          if (error?.error?.statusCode === 403) {
            this.recordsService.displayErrorDialog(
              licensePriority,
              getRecordRole(roles as RecordsRoles[]),
              this.dialog,
              error
            );
            return;
          }
          this.showSnackBar(
            this.store.selectSignal(dictionarySelector)()
              ?.uploadfileerrordialog_body ?? ''
          );
        },
      });
  }

  private showSnackBar(message: string): void {
    this.store.dispatch(
      new SdSnackBarDisplaySnackBar({
        message: message,
        duration: 3000,
        verticalPosition: 'bottom',
      })
    );
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }
}
