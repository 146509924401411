import { createEffect, ofType } from '@ngrx/effects';
import { select } from '@ngrx/store';
import { Injectable } from '@angular/core';
import {
  catchError,
  forkJoin,
  from,
  map,
  mergeMap,
  of,
  switchMap,
  take,
} from 'rxjs';
import { cloudFoldersFilesEntityByIdSelector } from 'src/app/files/store/cloud-folders-files.selectors';
import { fileHistoryByIdSelector } from 'src/app/files/store/file-history.selector';
import {
  DownloadFileRequested,
  FileItemActionsTypes,
  OpenPDFTronViewerRequested,
  SaveDocumentOnMobile,
  SaveFileLocallyRequested,
} from 'src/app/files/store/file-item.actions';
import { FileItemEffects } from 'src/app/files/store/file-item.effects';
import { MobileFilesHelper } from '../helpers/mobile-files.helper';
import { SdSnackBarDisplaySnackBar } from '../../../../shared/sd-snack-bar/store/sd-snack-bar.actions';
import { dictionarySelector } from '../../../../dictionary/store/dictionary.selectors';
import { MobilePdftronViewerDialogComponent } from '../shared/mobile-pdftron-viewer-dialog/mobile-pdftron-viewer-dialog.component';

@Injectable()
export class MobileFileItemEffects extends FileItemEffects {
  SaveFileLocallyRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<SaveFileLocallyRequested>(
        FileItemActionsTypes.SaveFileLocallyRequested
      ),
      switchMap(action =>
        forkJoin([
          this.store.pipe(
            select(fileHistoryByIdSelector({ id: action.payload.id })),
            take(1)
          ),
          this.store.pipe(
            select(
              cloudFoldersFilesEntityByIdSelector({ id: action.payload.id })
            ),
            take(1)
          ),
        ])
      ),
      map(([fileItem, cloudFolderFileItem]) => ({
        fileItem: fileItem ?? cloudFolderFileItem,
      })),
      map(({ fileItem }) => {
        return new DownloadFileRequested({
          id: fileItem.id,
          callback: async (file: Blob) => {
            const blob = new Blob([file], { type: file.type });
            const success = await MobileFilesHelper.saveFileInDevice(
              blob,
              fileItem
            )
              .then(() => true)
              .catch(e => {
                console.error(e);
                return false;
              });
            this.store.dispatch(
              new SdSnackBarDisplaySnackBar({
                message: !success
                  ? this.store.selectSignal(dictionarySelector)()
                      ?.globalparams_downloadfails ??
                    'globalparams_downloadfails'
                  : this.store.selectSignal(dictionarySelector)()
                      ?.globalparams_downloadsuccess ??
                    'globalparams_downloadsuccess',
              })
            );
          },
        });
      })
    );
  });

  OpenPDFTronViewerRequested$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<OpenPDFTronViewerRequested>(
          FileItemActionsTypes.OpenPDFTronViewerRequested
        ),
        switchMap(action =>
          this.dialog
            .open(MobilePdftronViewerDialogComponent, {
              autoFocus: false,
              data: {
                fileItem: action.payload.fileItem,
                file: action.payload.file,
                extension: action.payload.extension,
              },
              panelClass: 'custom-share-resource-dialog-pdfTron',
              maxHeight: '80vh',
              maxWidth: '90vw',
              height: '80vh',
              width: '90vw',
              disableClose: true,
            })
            .afterClosed()
        )
      ),
    { dispatch: false }
  );

  SaveDocumentOnMobile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SaveDocumentOnMobile>(FileItemActionsTypes.SaveDocumentOnMobile),
        switchMap(action =>
          from(
            MobileFilesHelper.saveFileInDevice(
              action.payload.blob,
              null,
              action.payload.file.name + '.pdf'
            )
          ).pipe(
            map(result => {
              this.store.dispatch(
                new SdSnackBarDisplaySnackBar({
                  message:
                    this.store.selectSignal(dictionarySelector)()
                      ?.globalparams_downloadsuccess ??
                    'globalparams_downloadsuccess',
                })
              );
            }),
            catchError(error => {
              this.store.dispatch(
                new SdSnackBarDisplaySnackBar({
                  message:
                    this.store.selectSignal(dictionarySelector)()
                      ?.globalparams_downloadfails ??
                    'globalparams_downloadfails',
                })
              );
              return of({ error });
            })
          )
        )
      ),
    { dispatch: false }
  );
}
